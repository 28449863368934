import * as React from "react"

export default function Vision({ blocks }) {
  
  const columns = blocks[0].relationships
  const { field_col_1, field_col_2, field_col_3 } = columns
  const h2 = field_col_1[0].field_header_title
  const column2 = field_col_2[0].field_news_text_body.processed
  const column3 = field_col_3[0].field_news_text_body.processed

  return (
    <div className="overflow-hidden bg-white">
      <div className="relative mx-auto max-w-xl lg:max-w-7xl py-16 px-4 sm:px-6 lg:px-8 lg:py-24">
        <div className="relative lg:grid lg:grid-cols-3 lg:gap-x-8 text-gray-500">
          <div className="lg:col-span-1">
            <h2 className="mt-2 mb-5 text-3xl font-bold tracking-tight text-sky-900">{h2}</h2>
          </div>
          <div className="textarea" dangerouslySetInnerHTML={{ __html: column2 }}></div>
          <div className="textarea" dangerouslySetInnerHTML={{ __html: column3 }}></div>
        </div>
      </div>
    </div>
  )
}
