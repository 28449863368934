import * as React from "react"
import {
  getSrc,
} from "gatsby-plugin-image"

export default function Values({ blocks }) {

  const columns = blocks[0].relationships
  const { field_col_1, field_col_2, field_col_3 } = columns

  const title = field_col_1[0].field_header_title

  const column1 = field_col_2.map(card => {
    return {
      name: card.field_card_title,
      description: card.field_card_body.processed,
      icon: getSrc(card.relationships.field_card_icon.relationships.field_media_image.localFile),
    }
  })

  const column2 = field_col_3.map(card => {
    return {
      name: card.field_card_title,
      description: card.field_card_body.processed,
      icon: getSrc(card.relationships.field_card_icon.relationships.field_media_image.localFile),
    }
  })

  const features = [...column1, ...column2]

  return (
    <div className="overflow-hidden bg-gray-50 border-b-4 border-white">
      <div className="relative mx-auto max-w-xl lg:max-w-7xl py-16 px-4 sm:px-6 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:py-24 lg:px-8">
        <div>
          <p className="mt-2 text-3xl font-bold tracking-tight text-sky-900">{title}</p>
          <p className="mt-4 text-lg text-gray-500">
          </p>
        </div>
        <div className="mt-12 lg:col-span-2 lg:mt-0">
          <dl className="mt-10 space-y-10 sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 sm:space-y-0 lg:col-span-2 lg:mt-0">
            {features.map((feature) => (
              <div key={feature.name}>
                <dt>
                  <div className="flex h-12 w-12 items-center justify-center">
                    <img className="h-12 w-12" alt={feature.name} src={feature.icon} aria-hidden="true" />
                  </div>
                  <p className="mt-4 text-xl font-semibold tracking-tight text-gray-900">{feature.name}</p>
                </dt>
                <dd className="mt-2 text-base text-gray-500"
                    dangerouslySetInnerHTML={{ __html: feature.description }}></dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}