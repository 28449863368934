import * as React from "react"
import {
  getSrc,
} from "gatsby-plugin-image"

export default function OurDifference({ blocks }) {
  
  const centredHeader = blocks[0]

  const columns = blocks[1].relationships
  const { field_col_1, field_col_2, field_col_3 } = columns

  const column1 = field_col_1.map(card => {
    return {
      name: card.field_card_title,
      description: card.field_card_body.processed,
      icon: getSrc(card.relationships.field_card_icon.relationships.field_media_image.localFile),
    }
  })

  const column2 = field_col_2.map(card => {
    return {
      name: card.field_card_title,
      description: card.field_card_body.processed,
      icon: getSrc(card.relationships.field_card_icon.relationships.field_media_image.localFile),
    }
  })

  const column3 = field_col_3.map(card => {
    return {
      name: card.field_card_title,
      description: card.field_card_body.processed,
      icon: getSrc(card.relationships.field_card_icon.relationships.field_media_image.localFile),
    }
  })

  return (
    <div id="our-difference" className="bg-white overflow-hidden relative">
      <div className="mx-auto max-w-xl py-16 px-4 sm:px-6 sm:py-24 lg:px-8 lg:max-w-7xl">
        <svg className="opacity-40 fill-gray-200 absolute -bottom-[18rem] lg:-bottom-[36rem] left-full sm:block w-full lg:w-11/12 transform -translate-x-2/4" viewBox="0 0 312 239">
          <path d="M162.713 222.751L216.32 58.3815C257.999 40.4249 303.174 20.9303 311.085 17.5158L311.999 16.2511L312 16.2506L162.713 222.751ZM107.172 226.654C107.172 226.654 77.059 143.154 76.7899 142.41C76.5879 141.87 72.0789 129.242 85.234 118.205C85.32 118.135 94.721 110.524 104.296 102.928L129.24 157.51C129.246 157.549 129.253 157.587 129.26 157.626L129.289 157.621L160.206 225.275L160.449 225.867C155.292 232.83 148.725 239 133.792 239C120.304 239 112.555 231.96 107.172 226.654ZM105.253 101.147C144.235 70.2672 223.162 8.4806 233.566 0.338028L215.021 57.1994C178.915 72.754 145.628 87.07 141.782 88.6378C131.301 92.9098 119.499 102.054 122.784 121.351C123.853 127.632 125.819 138.678 128.074 151.11L105.326 101.334L105.253 101.147ZM104.102 101.04C94.252 108.845 84.315 116.892 84.216 116.971C70.158 128.768 75.236 142.822 75.289 142.963L104.581 224.19L0.463922 126.318L29.5199 84.4332C38.6429 74.4596 49.932 70.0202 61.059 70.0202C78.203 70.0216 94.959 80.5685 102.78 97.6749L104.24 100.879L104.102 101.04ZM130.778 157.027L160.939 223.027L214.361 59.2252C178.747 74.567 146.192 88.5665 142.385 90.1183C132.449 94.1706 121.255 102.83 124.362 121.082C125.604 128.387 128.059 142.139 130.778 157.027Z" />
        </svg>
        <div className="mx-auto max-w-3xl lg:text-center text-lg text-gray-500">
          <h2 className="text-3xl font-bold tracking-tight text-sky-900">{centredHeader.field_centered_title}</h2>
          <div className="textarea" 
               dangerouslySetInnerHTML={{ __html: centredHeader?.field_centered_subtitle?.processed }}
          />
        </div>
        <div className="grid sm:gap-x-6 lg:grid-cols-3 lg:gap-x-8 lg:grid-cols-2">
          <div>
            <dl className="mt-12">
            {column1.map((item) => (
              <div key={item.name} className="relative mb-9">
                <dt>
                  <img className="h-12 s-12 lg:h-10 lg:w-10" alt="Checkmark" src={item.icon} aria-hidden="true" />
                  <p className="text-xl mt-4 font-semibold tracking-tight text-sky-900">{item.name}</p>
                </dt>
                <dd className="mt-2 text-base text-gray-500" dangerouslySetInnerHTML={{ __html: item.description }} />
              </div>
            ))}
            </dl>
          </div>
        <div>
          <dl className="lg:mt-12">
          {column2.map((item) => (
            <div key={item.name} className="relative mb-9">
              <dt>
                <img className="h-12 s-12 lg:h-10 lg:w-10" alt="Checkmark" src={item.icon} aria-hidden="true" />
                <p className="text-xl mt-4 font-semibold tracking-tight text-sky-900">{item.name}</p>
              </dt>
              <dd className="mt-2 text-base text-gray-500" dangerouslySetInnerHTML={{ __html: item.description }} />
            </div>
          ))}
          </dl>
        </div>
        <div>
          <dl className="lg:mt-12">
              {column3.map((item) => (
                <div key={item.name} className="relative mb-9">
                  <dt>
                    <img className="h-12 s-12 lg:h-10 lg:w-10" alt="Checkmark" src={item.icon} aria-hidden="true" />
                    <p className="text-xl mt-4 font-semibold tracking-tight text-sky-900">{item.name}</p>
                  </dt>
                  <dd className="mt-2 text-base text-gray-500" dangerouslySetInnerHTML={{ __html: item.description }} />
                </div>
                  ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  )
}
