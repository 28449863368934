import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../layouts/layout"
import About from "../components/about"
import Board from "../components/board"
import ManagementTeam from "../components/management-team"
import Misson from "../components/mission"
import Vision from "../components/vision"
import Values from "../components/values"
import OurDifference from "../components/our-difference"

const AboutUs = ({ data }) => {
  const sections = data.sections.relationships.field_sections
  const aboutSections = sections[0]
  const aboutBlocks = aboutSections.relationships.field_section_blocks

  const missionSection = sections[1]
  const missionBlock = missionSection.relationships.field_section_blocks.find(block => {
    return typeof block.field_centered_title !== "undefined"
  })

  const visionSection = sections[2]
  const visionBlocks = visionSection.relationships.field_section_blocks

  const valuesSection = sections[3]
  const valuesBlocks = valuesSection.relationships.field_section_blocks

  const ourDifferenceSection = sections[4]
  const ourDifferenceBlocks = ourDifferenceSection.relationships.field_section_blocks

  const ourBoardSection = sections[5]
  const ourBoardBlocks = ourBoardSection.relationships.field_section_blocks

  const teamSection = sections[6]
  const teamBlocks = teamSection.relationships.field_section_blocks
  
  return (
    <Layout showSubnav="true" >
    <About blocks={aboutBlocks} />
    <Misson block={missionBlock} />
    <Vision blocks={visionBlocks} />
    <Values blocks={valuesBlocks} />
    <OurDifference blocks={ourDifferenceBlocks} />
    <Board blocks={ourBoardBlocks} />
    <ManagementTeam blocks={teamBlocks} />
    </Layout>
  )
}

export const Head = ({ location, data }) => {
  const canonicalUrl = `${process.env.GATSBY_SITE_URL}about-us/`
  const metaDescription = data.sections?.field_metatags?.description ?? ``
  return (
    <>
      <meta name="description" content={metaDescription} />
      <title>About us | DEC Housing</title>
      <link rel="canonical" href={canonicalUrl} />
    </>
  )
}

export default AboutUs

export const query = graphql`
  query AboutPageQuery {
    sections: nodePage(drupal_internal__nid: {eq: 8}) {
      field_metatags {
        description
      }
      relationships {
        field_sections {
          ... on paragraph__section {
            relationships {
              field_section_blocks {
                ...GridFields
                ... on paragraph__two_column_block {
                  relationships {
                    field_column_one {
                      ... on Node {
                        ...Heading1Fields
                        ...TextFields
                      }
                    }
                    field_column_two {
                      ... on Node {
                        ...ImageFields
                      }
                    }
                  }
                }
                ...CentredHeaderFields
                ... on paragraph__three_column_block {
                  relationships {
                    field_col_1 {
                      ... on Node {
                        ...HeaderFields
                        ...CardFields
                      }
                    }
                    field_col_2 {
                      ... on Node {
                        ...TextFields
                        ...CardFields
                      }
                    }
                    field_col_3 {
                      ... on Node {
                        ...TextFields
                        ...CardFields
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      title
    }  
  }
`