import * as React from "react"
import {
  GatsbyImage, 
  getImage,
} from "gatsby-plugin-image"

export default function MediaImage({ image }) {
  const alt = image.field_media_image.alt
  const imageFile = getImage(image?.relationships?.field_media_image?.localFile)
	return (
		<div className="relative -mx-4 lg:ml-0 lg:-mr-2 lg:mt-0" aria-hidden="true">
      <div className="mx-auto lg:pl-12">
        <GatsbyImage className="px-12 h-[340px]" image={imageFile} alt={alt} />
      </div>
    </div>
	)
}