import * as React from "react"

export default function Mission({ block }) {
  return (
    <div id="mission" className="bg-gray-50 relative overflow-hidden py-16 sm:py-24 lg:py-24">
      <div className="mx-auto max-w-xl lg:max-w-7xl px-4 lg:text-center sm:px-6 lg:max-w-7xl lg:px-8">
        <div>
          <p className="mt-2 text-3xl font-bold tracking-tight text-sky-900">{block.field_centered_title}</p>
          <div className="mx-auto mt-5 max-w-prose text-xl text-gray-500" 
               dangerouslySetInnerHTML={{ __html: block?.field_centered_subtitle?.processed }}>  
          </div>
        </div>
      </div>
    </div>
  )
}