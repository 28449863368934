import * as React from "react"
import MediaImage from "./fields/media-image"

export default function About({ blocks }) {
  const column1 = blocks[0].relationships.field_column_one
  const h1 = column1[0].field_h1_title
  const text = column1[1].field_news_text_body.processed
  
  const column2 = blocks[0].relationships.field_column_two
  const image = column2[0].relationships?.field_media
  
  return (
    <div className="overflow-hidden bg-white pb-16 pt-36 lg:pt-64 lg:pb-24">
      <div className="relative mx-auto max-w-xl lg:max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="relative lg:grid lg:grid-cols-2 lg:items-center lg:gap-8">
          <div className="relative text-lg text-gray-500">
            <h1 className="text-4xl font-bold tracking-tight text-sky-900 sm:text-5xl mb-6">{h1}</h1>
            <div className="textarea" dangerouslySetInnerHTML={{ __html: text }}></div>
          </div>
          {image && <MediaImage image={image} />}
        </div>
      </div>
    </div>
  )
}