import * as React from "react"
import {
  GatsbyImage,
  getImage,
} from "gatsby-plugin-image"

export default function ManagementTeam({ blocks }) {
  
  const centredHeader = blocks[0]
  const team = blocks[1].relationships.field_grid
  const people = team.map(member => {
    return {
      name: member.field_staff_name,
      role: member.field_staff_role,
      linkedInUrl: member.field_staff_linkedin,
      image: getImage(member.relationships.field_staff_image.relationships.field_media_image.localFile),
    }
  })

  return (
    <div id="management-team" className="bg-white">
      <div className="mx-auto max-w-xl lg:max-w-7xl pt-12 pb-16 px-4 text-center sm:px-6 lg:px-8 sm:py-24 lg:pt-24 lg:pt-24">
        <div className="space-y-8 sm:space-y-12">
          <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl text-xl text-gray-500">
            <h2 className="text-3xl font-bold text-sky-900 tracking-tight sm:text-4xl">{centredHeader.field_centered_title}</h2>
            <div className="textarea"
                  dangerouslySetInnerHTML={{ __html: centredHeader?.field_centered_subtitle?.processed }} />
          </div>
          <ul
            className="grid mx-auto grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-2 md:gap-x-6 lg:max-w-xl lg:max-w-5xl lg:grid-cols-3 lg:gap-x-8 lg:gap-y-12 xl:max-w-5xl xl:grid-cols-5"
          >
            {people.map((person) => (
              <li key={person.name}>
                <div className="space-y-4">
                  <GatsbyImage className="mx-auto h-20 w-20 rounded-full lg:h-24 lg:w-24" 
                               image={person.image} alt={person.name} />
                  <div className="space-y-2">
                    <div className="text-xs font-medium lg:text-sm">
                      <p className="text-base">{person.name}</p>
                      <p className="text-sky-700">{person.role}</p>
                    </div>
                    {person.linkedInUrl && <ul className="ml-0 grid flex justify-center space-x-5">
                      <li>
                        <a target="_blank" rel="external noreferrer" href={person.linkedInUrl.uri} className="text-gray-500 hover:text-gray-600">
                          <span className="sr-only">LinkedIn</span>
                          <svg className="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                            <path
                              fillRule="evenodd"
                              d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </a>
                      </li>
                    </ul>}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}
